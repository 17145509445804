import group_1 from '../../assets/image/Group 1.png';
import fb from '../../assets/image/fb.svg';
import yt from '../../assets/image/youtube.svg';
import twitt from '../../assets/image/twitter.svg';
import {Link} from "react-router-dom";
import {ReactComponent as Tiktok} from "../../assets/image/tiktok-svgrepo-com.svg";
import './Footer.scss';

const Footer = () => {

    return (
        <footer className="row text-white">
            <div className="col-12 layout-padding">
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mb-2 ">
                        <div className="app-logo d-flex align-items-center">
                            <div className="logo me-2">
                                <img className={'rounded-circle'} src={group_1} alt={'logo'}/>
                            </div>
                            <div className="text-uppercase d-flex flex-column mb-3">
                                <p className="mb-2 title">ACCFIFA.VN</p>
                                <p className="m-0 slogan">uy tín - chất lượng - nhanh gọn</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mb-2">
                        <ul className="footer-menu">
                            <li><span>Hỗ trợ </span> <b className="text-orange">24/7</b></li>
                            <li className="mb-3">
                                <b className="text-orange text-uppercase">
                                    Giao dịch nhanh chóng - Uy tín - chất lượng
                                </b>
                            </li>
                            <li>
                                <span className="text-white me-1">Liên hệ</span>
                                <a className="text-decoration-none" href="https://www.facebook.com/accfifa.vn" target="_blank">
                                    <span className="text-orange">Trực tiếp qua fanpage để được hỗ trợ nhanh nhất</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mb-2">
                        <div className="row">
                            <div className="col-6">
                                <ul className="footer-menu">
                                    <li className="my-3"><Link className="text-orange" to={'/bao-mat'}>Bảo mật tài khoản</Link></li>
                                    <li className="my-3"><Link className="text-orange" to={'/dieu-khoan'}>Điều khoản</Link></li>
                                    <li className="my-3"><Link className="text-orange" to={'/nap-tien'}>Nạp tiền</Link></li>
                                </ul>
                            </div>
                            <div className="col-6">
                                <ul className="footer-social">
                                    <li>
                                        <a href="https://www.facebook.com/accfifa.vn" target="_blank" className="fb"><img src={fb} alt="fb"/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UC50CxmmipRh7atCLOY9HqMg" target="_blank" className="yt"><img src={yt} alt="yt"/></a>
                                    </li>
                                    <li>
                                        <a href="https://zalo.me/g/osfbcv485" target="_blank" className="twit">Z</a>
                                    </li>
                                    <li>
                                        <a href="https://www.tiktok.com/@fgcaccfifa.vn" target="_blank" className="tiktok">
                                            <Tiktok style={{
                                                width: 32,
                                                height: 32,
                                                fill: "rgb(252 200 59)"
                                            }}/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer;



